<div class="lu-row" [cdkTrapFocus]="options.isOpened">
	<div (click)="closeDialog()" [class.gs-overlay]="options.isOpened"></div>

	<div class="gs-wrap search-admin" [class.gs-over-z]="options.isOpened">
		<lu-icon icon="lu-search" class="magnifier magnifier--admin"></lu-icon>
		<form [formGroup]="searchForm" role="search" [attr.aria-label]="'lup.a11y.global' | translate">
			<formly-form [fields]="searchField" [form]="searchForm" [model]="searchModel"></formly-form>
		</form>

		<div class="search-options">
			<span *ngIf="isLoading" class="gs-spinner">
				<lu-icon [type]="LuIconType.SOLID" icon="spinner fa-spin icons-search icons-search--admin"></lu-icon>
			</span>

			<button class="icons-search icons-search--admin def-focus" [class.active]="searchBy === item.type"
				(click)="setSearchByOption(item.type)" [tooltipAnimation]="false"
				[attr.tabindex]="searchBy === item.type ? -1 : 0"
				[attr.aria-label]="('lup.a11y.search_for' | translate) + ' ' + (item.translateKey | translate)"
				[tooltip]="item.translateKey | translate" *ngFor="let item of searchTypes">
				<lu-icon [icon]="item.icon"></lu-icon>
				<p>{{ item.translateKey | translate }}</p>
			</button>
		</div>
	</div>

	<div class="search-box search-box--admin" *ngIf="options.isOpened" id="search-box">
		<div class="gs-results-wrap">
			<div class="gs-results-list" luInfiniteScroll (scrolled)="loadMore()">
				<div class="gs-result" [class.gs-over-z]="options.isOpened" [attr.aria-live]="ariaLiveToggle">
					<div class="crd crd--list crd--3dot slide-down crd--no-effect"
						*ngIf="!isLoading && searchModel?.headerSearch?.length < options.minimumCharsRequired"
						[attr.aria-hidden]="ariaHiddenToggle">
						<span>{{ ('lup.global_search.min_chars.other' | translate:{ count: options.minimumCharsRequired, num: options.minimumCharsRequired }) }}</span>
					</div>
					<div class="crd crd--list crd--3dot slide-down crd--no-effect"
						*ngIf="!isLoading && results && !results.data.length && searchModel?.headerSearch?.length >= options.minimumCharsRequired">
						<span>{{ NotFoundKeys[searchBy] | translate }}</span>
					</div>
					<div class="sr-only" *ngIf="!isLoading && results && results.data.length">
						<span>{{ results.data.length < results.page_size ? ('a11y.gs_result_less_than_ten' | translate:{ number_of_results: results.data.length }) : ('a11y.gs_result_more_than_ten' | translate:{ number_of_results: results.data.length }) }}</span>
					</div>
				</div>
				<div class="gs-result" [class.gs-over-z]="options.isOpened"
							*ngIf="!isLoading && results && results.data.length">
					<div *ngFor="let item of results.data; let i = index" [attr.aria-label]="'lup.a11y.global_search.results_index' | translate:{ item_name: tileProperty(searchBy, item), item_index: i + 1, results_length: results.data.length }">
						<angie-smart-search-tile-wrapper [view]="searchTileType()" [data]="item">
						</angie-smart-search-tile-wrapper>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
