import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResponse, CoreRoutes, DataService, DsConfig, PopupMessage } from 'src/app/core';
import { IAuthData, ISigninResponse, ISignUp } from '../models/auth.model';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	authData: IAuthData;
	toastrMessages: PopupMessage[];

	constructor(private dataService: DataService) {}

	signInWithCourse(
		email: string,
		username: string,
		password: string,
		courseId: number,
		embed: boolean = false
	): Observable<BaseResponse<ISigninResponse>> {
		const data = { user: { login: email || username, password }, embed_course_id: courseId };
		const dsConfig: DsConfig = this.dataService.getDefaultConfig(CoreRoutes.SIGN_IN);
		if (embed) dsConfig.url += '?signinEmbed';
		return this.dataService.post<any>(dsConfig, data);
	}

	signIn({ email, username, password, next, authCode }): Observable<BaseResponse<ISigninResponse>> {
		const data = { user: { login: email || username, password, next }, next, auth_code: authCode };
		const dsConfig: DsConfig = this.dataService.getDefaultConfig(CoreRoutes.SIGN_IN);
		return this.dataService.post<any>(dsConfig, data);
	}

	changePassword(
		current_password: string,
		password: string,
		password_confirmation: string
	): Observable<BaseResponse<any>> {
		const dsConfig: DsConfig = this.dataService.getDefaultConfig(CoreRoutes.CHANGE_PASSWORD);
		return this.dataService.put<any>(dsConfig, { user: { current_password, password, password_confirmation } });
	}
	signUp(data: ISignUp): Observable<BaseResponse<ISigninResponse>> {
		const dsConfig: DsConfig = this.dataService.getDefaultConfig(CoreRoutes.SIGN_UP);
		return this.dataService.post<any>(dsConfig, data);
	}
	codeRedeem(code: string): Observable<BaseResponse<any>> {
		const dsConfig: DsConfig = this.dataService.getDefaultConfig(CoreRoutes.CODE_REDEEM);
		return this.dataService.post<any>(dsConfig, { code });
	}
	resetPassword(
		reset_password_token: string,
		password: string,
		password_confirmation: string
	): Observable<BaseResponse<any>> {
		const dsConfig: DsConfig = this.dataService.getDefaultConfig(CoreRoutes.RESET_PASSWORD);
		return this.dataService.put<any>(dsConfig, { user: { reset_password_token, password, password_confirmation } });
	}
	resetDefaultPassword(
		reset_password_token: string,
		password: string,
		password_confirmation: string
	): Observable<BaseResponse<any>> {
		const dsConfig: DsConfig = this.dataService.getDefaultConfig(CoreRoutes.RESET_DEFAULT_PASSWORD);
		return this.dataService.put<any>(dsConfig, { user: { reset_password_token, password, password_confirmation } });
	}
	forgotPassword(email: string): Observable<BaseResponse<any>> {
		const dsConfig: DsConfig = this.dataService.getDefaultConfig(CoreRoutes.FORGOT_PASSWORD);
		return this.dataService.post<any>(dsConfig, { user: { login: email } });
	}
}
