import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

/**
 * Radio Component for formly
 *
 * We keep it on our side temp until ngx-formly switches to ivy
 *
 * https://github.com/ngx-formly/ngx-formly/issues/2532#issuecomment-832734418
 */
@Component({
	templateUrl: './radio.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioComponent extends FieldType {
	defaultOptions = {
		templateOptions: {
			options: [],
			formCheck: 'default' // 'default' | 'inline'
		}
	};
}
