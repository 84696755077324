<div class="admin-nav" [ngClass]="{ 'admin-nav--collapsed' : !navOpened }" luScrollWatch>
	<header>
		<button class="admin-nav__cta header-color-icon hdn-to-xxs" (click)="toggle()">
			<lu-icon class="icon-large admin-nav__ico" customClass="header-color-icon" icon="lu-hamburger-menu"></lu-icon>
		</button>
		<button class="admin-nav__cta header-color-icon hdn-from-xs" (click)="toggleMobile()">
			<lu-icon class="icon-large admin-nav__ico" customClass="header-color-icon" icon="lu-hamburger-menu"></lu-icon>
		</button>
	</header>
	<nav class="admin-nav__nav" [ngClass]="{'admin-nav__nav--mobile-expanded' : mobileOpened, 'admin-nav__nav--hovered' : navHovered}">
		<div class="admin-nav__groups" *ngFor="let group of navs | keyvalue" (mouseenter)="navHovered = true" (mouseleave)="navHovered = false">
			<ng-container *ngFor="let nav of navs[group.key]">
				<a class="admin-nav__item" [ngClass]="{ 'admin-nav__item--collapsed' : !navOpened, 'admin-nav__item--active' : currentNav.url === nav.url }" (click)="redirect(nav)" routerLink="">
					<lu-icon customClass="{{ currentNav.url === nav.url ? 'admin-nav__ico--active' : '' }}" class="icon-large admin-nav__ico" [icon]="nav.iconClass"></lu-icon>					<span class="admin-nav__txt" [ngClass]="{ 'admin-nav__txt--collapsed' : !navOpened, 'admin-nav__txt--active' : currentNav.url === nav.url }">{{ nav.label | translate }}
						<span *ngIf="nav.showPromotionBadge" class="pill pill--new-feature">{{ "courses.new" | translate }}</span>
					</span>
				</a>
			</ng-container>
		</div>
	</nav>
</div>
