import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlySelectModule } from '@ngx-formly/core/select';

import { RadioComponent } from './radio.component';

@NgModule({
	declarations: [RadioComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,

		FormlySelectModule,
		FormlyModule.forChild({
			types: [
				{
					name: 'radio',
					component: RadioComponent,
					wrappers: ['form-field']
				}
			]
		})
	]
})
export class FormlyRadioModule {}
