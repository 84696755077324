import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { actionFooterService as ActionFooterServiceToken, LuActionFooterModule } from 'lu-action-footer';
import {
	dataService as BreadcrumbsDataServiceToken,
	LuBreadcrumbsModule,
	windowRefService as BreadcrumbsWindowRefServiceToken
} from 'lu-breadcrumbs';
import { LuIconModule } from 'lu-icon';
import { LuInfiniteScrollModule } from 'lu-infinite-scroll-directive';
import { LuScrollTopModule } from 'lu-scroll-top';
import { scrollService as ScrollWatchScrollServiceToken, ScrollWatchModule } from 'lu-scroll-watch-directive';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { environment } from 'src/environments/environment';
import { LanguagePickModalModule } from '../angie-shared/components/modals/language-pick-modal';
import { SupportAccessModalModule } from '../angie-shared/components/modals/support-access-modal/support-access-modal.module';
import { ActionFooterService, DataService, ScrollService, WindowRefService } from '../core';
import { angieRoutePrefix } from '../globals';
import { DrawerModule } from './components/drawer/drawer.module';
import { PrimarySpinnerComponent } from './components/primary-spinner/primary-spinner.component';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { LayoutCssTrackDirective } from './default-layout/directives/layout-css.directive';
import { LayoutToggleDirective } from './default-layout/directives/layout-toggle.directive';
import { LayoutTypeDirective } from './default-layout/directives/layout-type.directive';
import { FlashMessagesComponent } from './default-layout/flash-messages/flash-messages.component';
import { DefaultFooterComponent } from './default-layout/footer/default-footer.component';
import { DefaultHeaderComponent } from './default-layout/headers/default-header.component';
import { InboxComponent } from './default-layout/headers/inbox/inbox.component';
import { MinimalHeaderComponent } from './default-layout/headers/minimal-header/minimal-header.component';
import { MyPortalsComponent } from './default-layout/headers/my-portals/my-portals.component';
import { UserMenuComponent } from './default-layout/headers/user-menu/user-menu.component';
import { ActionNavigationComponent } from './default-layout/subheader/action-nav/action-nav.component';
import { PrimaryNavigationComponent } from './default-layout/subheader/primary-nav/primary-nav.component';
import { SubHeaderComponent } from './default-layout/subheader/sub-header.component';
import { EmptyLayoutComponent } from './empty-layout/empty-layout.component';
import { LayoutStructureService } from './services/layout-structure.service';
import { MiddleNavComponent } from './default-layout/subheader/middle-nav/middle-nav.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { LuInputModule } from 'lu-formly';
import { ResourcesDescriptionModalModule } from '../angie-shared/components/modals/resources-description-modal/resources-description-modal.module';
import { AngieSmartSearchTileWrapperComponent } from '../angie-shared/components/angie-smart-search/angie-smart-search-tile-wrapper.component';
import { UserTileComponent } from '../angie-shared/components/angie-smart-search/tiles/user-tile/user-tile.component';
import { CoursesTileComponent } from '../angie-shared/components/angie-smart-search/tiles/courses-tile/courses-tile.component';
import { GroupsTileComponent } from '../angie-shared/components/angie-smart-search/tiles/groups-tile/groups-tile.component';
import { LearningPathTileComponent } from '../angie-shared/components/angie-smart-search/tiles/learning-path-tile/learning-path-tile.component';
import { CatalogTileComponent } from '../angie-shared/components/angie-smart-search/tiles/catalog-tile/catalog-tile.component';
import { ResourcesTileComponent } from '../angie-shared/components/angie-smart-search/tiles/resources-tile/resources-tile.component';
import { AngieSmartSearchComponent } from '../angie-shared/components/angie-smart-search/angie-smart-search.component';
import { AngieSmartSearchService } from '../angie-shared/components/angie-smart-search/angie-smart-search.service';
import { CloseOnEscapeKeyModule } from '../angie-shared/directives/close-on-escape-key/close-on-escape-key.module';
import { LogoModule } from '../angie-shared/components/logo/logo.module';
import { ContrastDirective } from './default-layout/directives/contrast.directive';
import { SkipLinkComponent } from './default-layout/skip-link/skip-link.component';
import { PrimaryAdminNavComponent } from './default-layout/primary-admin-nav/primary-admin-nav.component';
import { PrimaryLearnerNavComponent } from './default-layout/primary-learner-nav/primary-learner-nav.component';
import { RoleCodeToLabelModule } from '../angie-shared/pipes/role-code-to-label/role-code-to-label.module';
import { PublicLayoutComponent } from './public-layout/public-layout.component';
import { PublicHeaderComponent } from './public-layout/header/public-header.component';
import { ProxyUserModalModule } from '../angie-shared/components/modals/proxy-user-modal/proxy-user-modal.module';
import { ProxyBannerComponent } from './default-layout/flash-messages/proxy-banner/proxy-banner.component';
import { ProxyBannerService } from './services/proxy-banner.service';

/**
 * All of the required imports that we use inside our layout module
 */
const imports = [
	RouterModule,
	CommonModule,
	LuIconModule,
	ScrollWatchModule.forRoot({
		scrollService: {
			provide: ScrollWatchScrollServiceToken,
			useExisting: ScrollService
		}
	}),
	LanguagePickModalModule,
	TranslateModule,
	LuActionFooterModule.forRoot({
		actionFooterService: {
			provide: ActionFooterServiceToken,
			useExisting: ActionFooterService
		}
	}),
	LuScrollTopModule,
	BsDropdownModule,
	ModalModule,
	TooltipModule,
	LuBreadcrumbsModule.forRoot({
		dataService: {
			provide: BreadcrumbsDataServiceToken,
			useExisting: DataService
		},
		pathPrefix: angieRoutePrefix,
		useHash: environment.useHash,
		windowRefService: {
			provide: BreadcrumbsWindowRefServiceToken,
			useExisting: WindowRefService
		}
	}),
	LuInfiniteScrollModule,
	SupportAccessModalModule,
	A11yModule,
	ReactiveFormsModule,
	FormlyModule,
	LuInputModule,
	ResourcesDescriptionModalModule,
	CloseOnEscapeKeyModule,
	LogoModule,
	DrawerModule,
	LogoModule,
	RoleCodeToLabelModule,
	ProxyUserModalModule
];

/**
 * All of the required declarations that we use inside our layout module
 */
const declarations = [
	EmptyLayoutComponent,
	PublicLayoutComponent,
	PublicHeaderComponent,
	DefaultHeaderComponent,
	DefaultFooterComponent,
	PrimaryNavigationComponent,
	ActionNavigationComponent,
	SubHeaderComponent,
	PrimarySpinnerComponent,
	DefaultLayoutComponent,
	InboxComponent,
	UserMenuComponent,
	MyPortalsComponent,
	FlashMessagesComponent,
	ProxyBannerComponent,
	LayoutTypeDirective,
	LayoutToggleDirective,
	LayoutCssTrackDirective,
	ContrastDirective,
	MinimalHeaderComponent,
	MiddleNavComponent,
	AngieSmartSearchTileWrapperComponent,
	UserTileComponent,
	CoursesTileComponent,
	GroupsTileComponent,
	LearningPathTileComponent,
	CatalogTileComponent,
	ResourcesTileComponent,
	AngieSmartSearchComponent,
	SkipLinkComponent,
	PrimaryAdminNavComponent,
	PrimaryLearnerNavComponent
];

const providers = [LayoutStructureService, AngieSmartSearchService, ProxyBannerService];

const exports = [DefaultLayoutComponent, PublicLayoutComponent];

/**
 * Layout Module is our default layout module
 *
 * I requires all of the essentials to have our layout built
 */

@NgModule({
	imports,
	declarations,
	providers,
	exports
})
export class LayoutModule {}
