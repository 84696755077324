<div class="minimal-header o-flex o-flex--vcenter o-flex--space-between" [ngClass]="{'disabled minimal-header--disabled': disabled$ | async}">
  <a *ngIf="linkConfig$ | async as linkConfig"
     class="link-back"
     [routerLink]="linkConfig?.routerLink"
     (click)="linkConfig?.callback && linkConfig.callback()">
    <!-- Placement of linkConfigTest is due to mistery whitespace which appears otherwise -->
    <lu-icon [icon]="linkConfig?.icon || 'lu-arrow-left'" class="mr-4"></lu-icon>{{linkConfig?.text}}
  </a>
	<div *ngIf="mainActionConfig$ | async as mainActionConfig" class="o-flex o-flex--flex-center o-flex--vcenter">
		<p class="h-m mr-4" [ngClass]="mainActionConfig?.class">{{ mainActionConfig.label }}</p>
		<button
			*ngIf="mainActionConfig.button"
			type="button"
			(click)="mainActionConfig.button.onClick && mainActionConfig.button.onClick()"
			class="btn btn--icon"
			[ngClass]="mainActionConfig.button.class || 'btn--ghost-theme'"
			[class.disabled]="mainActionConfig.button.disabled"
			[disabled]="mainActionConfig.button.disabled">
			<lu-icon [icon]="mainActionConfig.button.icon" [ngClass]="{'btn__ico': mainActionConfig.button.label}"></lu-icon>
			<span *ngIf="mainActionConfig.button.label" class="btn__txt">{{ mainActionConfig.button.label }}</span>
		</button>
	</div>
	<div *ngIf="actionConfig$ | async as actionConfig">
		<button
			*ngFor="let button of actionConfig.buttons"
			type="button" class="btn btn--actions-nav"
			[ngClass]="button.class || 'btn--default'"
			[class.disabled]="button.disabled"
			[disabled]="button.disabled"
			(click)="button.onClick && button.onClick()">
			<lu-icon *ngIf="button.icon" class="btn__ico" [icon]="button.icon"></lu-icon>
			<span class="btn__txt">{{ button.label }}</span>
		</button>
	</div>
</div>
