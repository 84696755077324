<div
	*ngIf="(config?.hidden$ | async) === false"
	class="o-flex o-flex--vcenter action-bar--wrapper {{config?.className}}"
	[ngClass]="{
		'disabled action-bar--disabled': config?.disabled$ | async,
		'o-flex--flex-start' : config?.actionSlot === 'START',
		'o-flex--flex-end' : config?.actionSlot === 'END' || !config?.actionSlot
		}">
	<ng-content select="start"></ng-content>
		<button
			*ngFor="let action of config?.actionItems"
			type="button"
			(click)="action.onClick()"
			[ngClass]="{
				'mr-4' : config?.actionSlot === 'START',
				'ml-4' : config?.actionSlot === 'END' || !config?.actionSlot
				}"
			[class]="'btn ' + (action.buttonClass || 'btn--theme')"
			[disabled]="action.disabled$ | async">
			<lu-icon *ngIf="action.icon" class="btn__ico" [icon]="action.icon"></lu-icon>
			<span class="btn__txt">{{ action.label || 'submit' | translate }}</span>
		</button>
	<ng-content select="end"></ng-content>
</div>
