<div
	*ngFor="let option of to.options | formlySelectOptions: field | async; let i = index"
	class="form-check custom-radio"
	[class.form-check-inline]="to.formCheck === 'inline'"
>
	<input
		type="radio"
		[id]="id + '_' + i"
		class="form-check-input"
		[name]="field.name || id"
		[class.is-invalid]="showError"
		[value]="option.value"
		[formControl]="formControl"
		[formlyAttributes]="field"
		[attr.disabled]="option.disabled || formControl.disabled ? true : null"
	/>
	<label class="form-check-label" [for]="id + '_' + i" [ngClass]="{ disabled: option.disabled }">
		{{ option.label }}
	</label>
</div>
