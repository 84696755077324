import { IltsRoutesConfig } from './ilts-routes.config';

/**
 * instructor-lead-trainings routes.
 * Leaving shorthand ILT rather then trainings or full name here
 * for sake of shorter paths on routes when used externally.
 */
export class IltsRoutes {
	// URL segment names - used as is for routing modules
	public static readonly ILTs = IltsRoutesConfig.LIVE_TRAININGS_BASE_URL;
	public static readonly SCHEDULE_SESSIONS = IltsRoutesConfig.SCHEDULE_SESSIONS_URL_SEGMENT;
	public static readonly SCHEDULE_SESSION_IN_PERSON = IltsRoutesConfig.SCHEDULE_SESSION_IN_PERSON_URL_SEGMENT;
	public static readonly SCHEDULE_SESSION_VIRTUAL = IltsRoutesConfig.SCHEDULE_SESSION_VIRTUAL_URL_SEGMENT;
	public static readonly CHOOSE_SESSION_TYPE = IltsRoutesConfig.CHOOSE_SESSION_TYPE_URL_SEGMENT;
	public static readonly EDIT_TRAINING = IltsRoutesConfig.EDIT_TRAINING_URL_SEGMENT;
	public static readonly NEW_TRAINING = IltsRoutesConfig.NEW_TRAINING_URL_SEGMENT;
	public static readonly OVERVIEW_TAB = IltsRoutesConfig.OVERVIEW_TAB_URL_SEGMENT;
	public static readonly SESSIONS_TAB = IltsRoutesConfig.SESSIONS_TAB_URL_SEGMENT;
	public static readonly REGISTERED_TAB = IltsRoutesConfig.REGISTERED_TAB_URL_SEGMENT;
	public static readonly WAITLISTED_TAB = IltsRoutesConfig.WAITLISTED_TAB_URL_SEGMENT;
	public static readonly PENDING_REGISTRATIONS = IltsRoutesConfig.PENDING_REGISTRATIONS_TAB_URL_SEGMENT;
	public static readonly SINGLE_SESSION = IltsRoutesConfig.SINGLE_SESSION_URL;
	public static readonly EDIT_SINGLE_SESSION = IltsRoutesConfig.SINGLE_SESSION_EDIT_URL;
	public static readonly MARK_ATTENDANCE_SINGLE_SESSION = IltsRoutesConfig.SINGLE_SESSION_MARK_ATTENDANCE_URL;
	public static readonly DOWNLOAD_ATTENDANCE_ROSTER = IltsRoutesConfig.SINGLE_SESSION_DOWNLOAD_ATTENDANCE_ROSTER_URL;

	// Used in actual components and htmls for routing
	public static readonly TRAININGS_HOME_ROUTE = '/' + IltsRoutes.ILTs;

	// URL params
	public static readonly TRAINING_ID_PARAM = IltsRoutesConfig.TRAINING_ID_PARAM;

	public static getTrainingHomeRoute(trainingId: number): string {
		return IltsRoutes.TRAININGS_HOME_ROUTE + '/' + trainingId;
	}

	public static getScheduleSessionsRoute(trainingId: number): string {
		return IltsRoutes.getTrainingHomeRoute(trainingId) + '/' + IltsRoutes.SCHEDULE_SESSIONS;
	}

	public static getScheduleInPersonSessionsRoute(trainingId: number): string {
		return IltsRoutes.getTrainingHomeRoute(trainingId) + '/' + IltsRoutes.SCHEDULE_SESSION_IN_PERSON;
	}

	public static getScheduleOnlineSessionsRoute(trainingId: number): string {
		return IltsRoutes.getTrainingHomeRoute(trainingId) + '/' + IltsRoutes.SCHEDULE_SESSION_VIRTUAL;
	}

	public static getChooseSessionTypeRoute(trainingId: number): string {
		return IltsRoutes.getTrainingHomeRoute(trainingId) + '/' + IltsRoutes.CHOOSE_SESSION_TYPE;
	}

	public static getEditTrainingRoute(trainingId: number): string {
		return IltsRoutes.getTrainingHomeRoute(trainingId) + '/' + IltsRoutes.EDIT_TRAINING;
	}

	public static getCreateTrainingRoute(): string {
		return IltsRoutes.TRAININGS_HOME_ROUTE + '/' + IltsRoutes.NEW_TRAINING;
	}

	public static getOverviewTabRoute(trainingId: number): string {
		return IltsRoutes.getTrainingHomeRoute(trainingId) + '/' + IltsRoutes.OVERVIEW_TAB;
	}

	public static getSessionsTabRoute(trainingId: number): string {
		return IltsRoutes.getTrainingHomeRoute(trainingId) + '/' + IltsRoutes.SESSIONS_TAB;
	}

	public static getPendingRegistrationsTabRoute(trainingId: number): string {
		return IltsRoutes.getTrainingHomeRoute(trainingId) + '/' + IltsRoutes.PENDING_REGISTRATIONS;
	}

	public static getSessionOverviewTabRoute(trainingId: number, sessionId: number): string {
		return IltsRoutes.getSessionsTabRoute(trainingId) + '/' + sessionId + '/' + IltsRoutes.OVERVIEW_TAB;
	}

	public static getSessionEditRoute(trainingId: number, sessionId: number): string {
		return IltsRoutes.getSessionsTabRoute(trainingId) + '/' + sessionId + '/edit';
	}

	public static getSessionOverviewRegisteredTabRoute(trainingId: number, sessionId: number): string {
		return `${IltsRoutes.getSessionsTabRoute(trainingId)}/${sessionId}/${IltsRoutes.OVERVIEW_TAB}/${
			IltsRoutes.REGISTERED_TAB
		}`;
	}

	public static getSessionOverviewWaitlistedTabRoute(trainingId: number, sessionId: number): string {
		return `${IltsRoutes.getSessionsTabRoute(trainingId)}/${sessionId}/${IltsRoutes.OVERVIEW_TAB}/${
			IltsRoutes.WAITLISTED_TAB
		}`;
	}

	public static getSessionRegistrantTabRoute(trainingId: number, sessionId: number): string {
		return IltsRoutes.getSessionsTabRoute(trainingId) + '/' + sessionId + '/' + IltsRoutes.PENDING_REGISTRATIONS;
	}
}
