import { COURSES_PAGE_META } from '../courses/courses-tracking.config';
import { REPORTS_BUILDER_PAGE_META } from '../reports-builder/reports-builder-tracking.config';
import { PageMeta } from './insights.models';
import {
	MANAGER_DASHBOARD_LANDING_PAGE_META,
	MANAGER_DASHBOARD_LIST_LEARNERS
} from '../dashboard/dashboard-tracking.config';

export const ROUTER_PAGE_META: Record<string, PageMeta> = {
	...COURSES_PAGE_META,
	...REPORTS_BUILDER_PAGE_META,
	...MANAGER_DASHBOARD_LANDING_PAGE_META,
	...MANAGER_DASHBOARD_LIST_LEARNERS
};
