import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActionBarConfig } from './angie-action-bar.model';
import { isObservable, of } from 'rxjs';
import { isNil } from 'lodash-es';

@Component({
	selector: 'angie-action-bar',
	templateUrl: './angie-action-bar.component.html',
	styleUrls: ['./angie-action-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AngieActionBarComponent {
	private _config: ActionBarConfig;
	@Input() set config(data: ActionBarConfig) {
		if (!data) return;
		this._config = this.mapConfig(data);
	}
	get config(): ActionBarConfig {
		return this._config;
	}
	private mapConfig(config: ActionBarConfig): ActionBarConfig {
		const mappedConfig = { ...config };
		mappedConfig.hidden$ = isObservable(config.hidden$) ? config.hidden$ : of(config.hidden$);
		mappedConfig.disabled$ = isObservable(config.disabled$) ? config.disabled$ : of(config.disabled$);
		mappedConfig.actionItems.forEach(item => {
			if (isNil(item.disabled$)) return;
			if (isObservable(item.disabled$)) return;
			item.disabled$ = of(item.disabled$);
		});
		return mappedConfig;
	}
}
