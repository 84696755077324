<footer class="main-footer" *ngIf="!portalMeta.loaded_in_salesforce">
	<div [attr.aria-label]="('lup.a11y.powered_by' | translate) + ' LearnUpon ' + ('lup.a11y.logo' | translate)" role="img" class="main-footer__logo-wrap" *ngIf="!footer.remove_powered_by">
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px" y="0px" viewBox="0 0 430 126" xml:space="preserve">
			<path d="M406.5,14.6h-99.7v3h99.6c5.8,0,10.5,4.7,10.5,10.5v84c0,5.8-4.7,10.5-10.5,10.5H23.6c-5.8,0-10.5-4.7-10.5-10.5v-84
			c0-5.8,4.7-10.5,10.5-10.5h100.3v-3H23.6c-7.4,0-13.5,6.1-13.5,13.5v84c0,7.4,6.1,13.5,13.5,13.5h383c7.4,0,13.5-6.1,13.5-13.5v-84
			C420,20.7,413.8,14.6,406.5,14.6z"></path>
				<path d="M103.3,37.7H66c-3.7,0-6.7,3-6.7,6.7v37.2c0,9.3,7.6,16.9,16.9,16.9h27.1c9.3,0,16.9-7.6,16.9-16.9V54.6
				C120.1,45.3,112.6,37.7,103.3,37.7z M89.7,88.4h-6.8c-7.5,0-13.5-6.1-13.5-13.5V61.3c0-1.9,1.5-3.4,3.4-3.4c1.9,0,3.4,1.5,3.4,3.4
				v13.5c0,3.7,3,6.8,6.8,6.8h6.8c1.9,0,3.4,1.5,3.4,3.4C93.1,86.9,91.6,88.4,89.7,88.4z M110,61.3c0,7.5-6.1,13.5-13.5,13.5
				c-7.5,0-13.5-6.1-13.5-13.5V51.2c0-1.9,1.5-3.4,3.4-3.4c1.9,0,3.4,1.5,3.4,3.4v10.2c0,3.7,3,6.8,6.8,6.8c3.7,0,6.8-3,6.8-6.8V51.2
				c0-1.9,1.5-3.4,3.4-3.4c1.9,0,3.4,1.5,3.4,3.4V61.3z"></path>
			<path d="M153.2,78.7V85h-19.4V51.5h6.6v27.2H153.2z"></path>
				<path d="M168.2,80.1c2.4,0,4.3-1,5.4-2.4l5,2.9c-2.3,3.3-5.9,5.1-10.5,5.1c-8.1,0-13.1-5.5-13.1-12.7c0-7.1,5.1-12.7,12.7-12.7
				c7.1,0,12.1,5.6,12.1,12.7c0,0.9-0.1,1.7-0.2,2.5h-18.1C162.3,78.7,164.9,80.1,168.2,80.1z M173.5,70.8c-0.8-3.5-3.4-4.8-5.9-4.8
				c-3.3,0-5.6,1.8-6.3,4.8H173.5z"></path>
				<path d="M208.3,61v24h-6.2v-2.8c-1.7,2.2-4.3,3.5-7.8,3.5c-6.4,0-11.7-5.5-11.7-12.7c0-7.1,5.3-12.7,11.7-12.7
				c3.5,0,6.1,1.3,7.8,3.5V61H208.3z M202.1,73c0-4-2.8-6.8-6.7-6.8c-3.8,0-6.6,2.7-6.6,6.8c0,4,2.8,6.8,6.6,6.8
				C199.3,79.8,202.1,77.1,202.1,73z"></path>
				<path
					d="M227.4,60.6v6.9c-3.2-0.4-7.3,1.1-7.3,6.1V85h-6.2V61h6.2v4.1C221.3,61.9,224.3,60.6,227.4,60.6z"></path>
				<path d="M253.4,70.3V85h-6.2v-14c0-3.3-2-4.9-4.7-4.9c-3,0-5.3,1.8-5.3,5.9v13H231V61h6.2v2.7c1.4-2.2,3.9-3.4,7.1-3.4
				C249.4,60.4,253.4,63.9,253.4,70.3z"></path>
				<path d="M258.6,74V51.5h6.6v22.1c0,3.3,1.7,5.7,6.2,5.7c4.5,0,6.2-2.4,6.2-5.7V51.5h6.6V74c0,7.2-5.5,11.7-12.9,11.7
				C264.1,85.7,258.6,81.3,258.6,74z"></path>
				<path d="M315.6,73c0,7.1-5.3,12.7-11.7,12.7c-3.5,0-6-1.3-7.8-3.5v12.4H290V61h6.2v2.8c1.7-2.2,4.3-3.5,7.8-3.5
				C310.4,60.4,315.6,65.9,315.6,73z M309.4,73c0-4-2.8-6.8-6.7-6.8c-3.8,0-6.6,2.7-6.6,6.8c0,4,2.8,6.8,6.6,6.8
				C306.6,79.8,309.4,77.1,309.4,73z"></path>
				<path d="M318.7,73c0-7.1,5.6-12.7,12.7-12.7c7.1,0,12.7,5.5,12.7,12.7c0,7.1-5.7,12.7-12.7,12.7C324.4,85.7,318.7,80.2,318.7,73z
				 M337.9,73c0-3.9-2.8-6.6-6.5-6.6c-3.6,0-6.5,2.7-6.5,6.6s2.8,6.6,6.5,6.6C335.1,79.7,337.9,76.9,337.9,73z"></path>
				<path d="M370.9,70.3V85h-6.2v-14c0-3.3-2-4.9-4.7-4.9c-3,0-5.3,1.8-5.3,5.9v13h-6.2V61h6.2v2.7c1.4-2.2,3.9-3.4,7.1-3.4
				C366.9,60.4,370.9,63.9,370.9,70.3z"></path>
				<path d="M150.6,9.4c0,3.9-2.9,6.8-6.9,6.8h-4.9v7.6h-2.8V2.6h7.7C147.7,2.6,150.6,5.5,150.6,9.4z M147.8,9.4c0-2.4-1.7-4.2-4.1-4.2
				h-4.9v8.4h4.9C146.1,13.6,147.8,11.8,147.8,9.4z"></path>
				<path d="M151.7,16.2c0-4.5,3.5-8,8-8c4.4,0,8,3.5,8,8c0,4.5-3.6,8-8,8C155.3,24.2,151.7,20.7,151.7,16.2z M165,16.2
				c0-3.1-2.3-5.4-5.4-5.4c-3,0-5.3,2.3-5.3,5.4c0,3.1,2.3,5.4,5.3,5.4C162.7,21.6,165,19.3,165,16.2z"></path>
				<path
					d="M190.8,8.6l-4.7,15.2h-2.7l-3.5-11.2l-3.5,11.2h-2.7l-4.7-15.2h2.8l3.3,11.5l3.6-11.5h2.5l3.6,11.6l3.3-11.6H190.8z"></path>
				<path d="M200.3,21.7c2.1,0,3.6-1,4.4-2.2l2.2,1.3c-1.3,2.1-3.6,3.4-6.7,3.4c-4.9,0-8.1-3.4-8.1-8c0-4.5,3.2-8,7.9-8
				c4.6,0,7.5,3.8,7.5,8c0,0.4,0,0.8-0.1,1.2h-12.7C195.3,20.1,197.5,21.7,200.3,21.7z M194.8,15.1h10.1c-0.5-3-2.6-4.4-4.9-4.4
				C197.2,10.7,195.2,12.5,194.8,15.1z"></path>
			<path d="M218,8.4v2.8c-2.3-0.1-4.7,1.1-4.7,4.6v8h-2.6V8.6h2.6v2.5C214.2,9,216.1,8.4,218,8.4z"></path>
				<path d="M227.1,21.7c2.1,0,3.6-1,4.4-2.2l2.2,1.3c-1.3,2.1-3.6,3.4-6.7,3.4c-4.9,0-8.1-3.4-8.1-8c0-4.5,3.2-8,7.9-8
				c4.6,0,7.5,3.8,7.5,8c0,0.4,0,0.8-0.1,1.2h-12.7C222.1,20.1,224.3,21.7,227.1,21.7z M221.6,15.1h10.1c-0.5-3-2.6-4.4-4.9-4.4
				C224,10.7,222,12.5,221.6,15.1z" ></path>
				<path d="M252.6,2.6v21.2H250v-2.6c-1.2,1.8-3.2,3-5.7,3c-4.2,0-7.7-3.5-7.7-8c0-4.5,3.5-8,7.7-8c2.6,0,4.5,1.2,5.7,3V2.6H252.6z
				 M250,16.2c0-3.1-2.3-5.4-5.4-5.4c-3,0-5.4,2.3-5.4,5.4c0,3.1,2.3,5.4,5.4,5.4C247.6,21.6,250,19.3,250,16.2z"></path>
				<path d="M280.2,16.2c0,4.5-3.5,8-7.7,8c-2.6,0-4.5-1.2-5.7-3v2.6h-2.6V2.6h2.6v8.7c1.2-1.8,3.2-3,5.7-3
				C276.8,8.2,280.2,11.7,280.2,16.2z M277.6,16.2c0-3.1-2.3-5.4-5.4-5.4c-3.1,0-5.4,2.3-5.4,5.4c0,3.1,2.3,5.4,5.4,5.4
				C275.2,21.6,277.6,19.3,277.6,16.2z"></path>
				<path
					d="M296.2,8.6L290,24.7c-1.3,3.4-3.6,5.3-6.7,5.1v-2.5c2,0.2,3.3-1.1,4.1-3.1l0.3-0.6L281,8.6h2.8l5.2,11.9l4.4-11.9H296.2z"></path>
		</svg>
	</div>
	<div class="footer-brand-logo" [attr.aria-label]="portalMeta.title + ' ' + ('lup.a11y.logo' | translate)" role="img" *ngIf="!!footer.remove_powered_by"></div>
	<p *ngIf="!footer.remove_powered_by" class="main-footer__copy-right footer-text-color">&copy; {{ currentYear }}
		LearnUpon Ltd.
	</p>
	<ul class="list-inline main-footer__links footer-text-color"
		*ngIf="footer.footer_links && footer.footer_links.length">
		<li *ngFor="let item of footer.footer_links">
			<a target="_new" [attr.alt]="item.url" [href]="item.url">
				{{ item.title }}
			</a>
		</li>
	</ul>
</footer>
